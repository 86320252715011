<template>
  <div>
    <div class="d-flex">
      <div class="title-presentation">
        <div v-if="evaluation" class="evaluation-name-title">
          {{ evaluation.title }}
        </div>
      </div>
      <ViewEvaluationGrade
        v-if="
          (evaluatee_view && isFinishTestEvaluation) ||
          (evaluator_view && selected_user)
        "
        class="grade-container"
        :user_id="evaluator_view ? selected_user : user.id"
        :evaluator_view="evaluator_view"
        :evaluation_id="evaluation_id"
      ></ViewEvaluationGrade>
    </div>
    <!-- OutputFormat -->
    <!-- <div class="container-exit-format d-flex">
      <b-button
        v-if="
          allows_crud &&
          evaluation &&
          !evaluation.is_locked &&
          !preview_evaluation
            ? allows_crud
            : false
        "
        v-b-tooltip.top.noninteractive.v-secondary="
            `Formato de salida Evaluación Parcial`
          "
        variant="primary"
        size="sm"
      >
        <b-icon-pencil-square class="pencil-icon"></b-icon-pencil-square>
        (FS) Evaluación Parcial
      </b-button>
    </div> -->
    <div class="d-flex">
      <b-button
        class="noprint ml-auto mr-0"
        v-if="
          !allows_crud && !evaluatee_view && !evaluator_view && fetch_completed
        "
        @click="fullImpressionEvaluation"
        variant="primary"
        size="sm"
      >
        <div class="d-flex" style="align-items: center">
          <b-icon icon="printer" class="mr-1"></b-icon>
          <span>Impresión completa de la evaluación</span>
        </div>
      </b-button>
    </div>
    <SelectedEvaluatee
      v-if="evaluator_view && evaluation"
      :evaluation="evaluation"
      :actualTest="testFilter[tabIndex]"
      :evaluator_view="evaluator_view"
      @selected_user="slotEvaluateeSelected"
    ></SelectedEvaluatee>
    <div
      class="container-buttons-evaluation"
      :class="{
        'btn-preview-evaluation': preview_evaluation == true,
        'btn-preview-evaluation-no-edit': !allows_crud,
        'btn-is-locked-activate': evaluation && evaluation.is_locked,
      }"
    >
      <b-button
        v-if="
          allows_crud &&
          evaluation &&
          !evaluation.is_locked &&
          !preview_evaluation
            ? allows_crud
            : false
        "
        variant="primary"
        size="sm"
        @click="$bvModal.show(`modal-edit-evaluation-${evaluation_id}`)"
      >
        <b-icon-pencil-square class="pencil-icon"></b-icon-pencil-square>
        Editar
        {{ $getVisibleNames("evaluations2.evaluation", false, "Evaluación") }}
      </b-button>
      <div>
        <b-button
          v-if="
            evaluation &&
            !evaluation.is_locked &&
            allows_crud &&
            user &&
            !user.groups.includes(6) &&
            !preview_evaluation
          "
          variant="primary"
          size="sm"
          class="lock-btn p-0 mr-2 noprint"
          v-b-tooltip.top.noninteractive.v-secondary="
            `Bloquear  ${$getVisibleNames(
              'evaluations2.evaluation',
              false,
              'Evaluación'
            )}`
          "
          @click="changeStateEvaluation()"
        >
          <b-icon-unlock-fill></b-icon-unlock-fill>
        </b-button>
        <b-button
          v-if="
            evaluation &&
            evaluation.is_locked &&
            allows_crud &&
            user &&
            !user.groups.includes(6) &&
            !preview_evaluation
          "
          variant="primary"
          size="sm"
          class="lock-btn p-0 mr-2 noprint"
          v-b-tooltip.top.noninteractive.v-secondary="
            `Desbloquear  ${$getVisibleNames(
              'evaluations2.evaluation',
              false,
              'Evaluación'
            )}`
          "
          @click="changeStateEvaluation()"
        >
          <b-icon-lock-fill></b-icon-lock-fill>
        </b-button>
        <b-button
          v-if="testFilter.length > 0 && !evaluatee_view && !evaluator_view"
          class="noprint"
          variant="primary"
          size="sm"
          @click="previewEvaluation"
        >
          {{
            !preview_evaluation ? "Ver como Estudiante" : "Ver como Profesor"
          }}
        </b-button>
      </div>
    </div>
    <b-modal
      :id="`modal-edit-evaluation-${evaluation_id}`"
      :title="`Editar ${$getVisibleNames(
        'evaluations2.evaluation',
        false,
        'Evaluación'
      )}`"
      hide-footer
      size="xl"
      v-if="evaluation"
    >
      <NewEvaluationForm
        :Evaluation="evaluation"
        :evaluation_scoped_id="evaluation.scope"
        :evaluation_scoped_object_id="evaluation.scope_id"
        :Sections="sections"
        :Matters="matters"
        :show_step="
          evaluation &&
          (evaluation.matter_evaluation != null ||
            evaluation.egress_profile_matter_evaluation != null ||
            evaluation.scope == 1)
        "
        :EgressProfileMatters="egress_profile_matters"
        :ClosedEvaluatee="existEvaluateeEvaluation"
        @closeModal="$bvModal.hide(`modal-edit-evaluation-${evaluation_id}`)"
      ></NewEvaluationForm>
    </b-modal>
    <!-- OutputFormat -->
    <HeaderOutputFormat
      v-if="matter_evaluation != null"
      :class="{
        'd-none':
          !isfullImpressionEvaluation &&
          !isPrintingRubrica &&
          !isPrintingInstrumento &&
          !isformatPartialTest &&
          !isformatTransversalInformation &&
          !isformatTransversalExam &&
          !isformatCorrectAnswerPartial &&
          !isformatCorrectAnswerTransversal,
      }"
      :evaluation_id="evaluation_id"
      :time_output_format="matter_evaluation"
      :isformatCorrectAnswerPartial="isformatCorrectAnswerPartial"
      :isformatCorrectAnswerTransversal="isformatCorrectAnswerTransversal"
    ></HeaderOutputFormat>
    <HeaderEvaluation
      :class="{
        'd-none':
          (isPrintingRubrica ||
            isPrintingInstrumento ||
            isfullImpressionEvaluation ||
            isformatPartialTest ||
            isformatTransversalInformation ||
            isformatTransversalExam ||
            isformatCorrectAnswerPartial ||
            isformatCorrectAnswerTransversal) &&
          matter_evaluation != null,
      }"
      :evaluation_id="evaluation_id"
      :allows_crud="
        allows_crud &&
        evaluation &&
        !evaluation.is_locked &&
        !preview_evaluation
          ? allows_crud
          : false
      "
      :evaluatee_view="evaluatee_view"
    ></HeaderEvaluation>

    <b-card
      :class="{ 'print-hide': isfullImpressionEvaluation == true }"
      class="container-instrument mt-4"
      no-body
    >
      <b-tabs
        v-model="tabIndex"
        class="pt-2"
        active-nav-item-class="font-weight-bold"
        :no-nav-style="
          isPrintingRubrica ||
          isPrintingInstrumento ||
          isformatPartialTest ||
          isformatTransversalInformation ||
          isformatTransversalExam ||
          isformatCorrectAnswerPartial ||
          isformatCorrectAnswerTransversal
        "
      >
        <b-tab v-for="(test, index) in testFilter" :key="test.id">
          <template #title>
            <div
              :class="{
                'print-hide':
                  isPrintingRubrica ||
                  isPrintingInstrumento ||
                  isformatPartialTest ||
                  isformatTransversalInformation ||
                  isformatTransversalExam ||
                  isformatCorrectAnswerPartial ||
                  isformatCorrectAnswerTransversal,
              }"
            >
              <span>
                <b-icon-chevron-down
                  v-if="tabIndex == index"
                  style="width: 1rem; height: 1rem"
                  variant="primary"
                  class="noprint spinner-tab"
                ></b-icon-chevron-down>
                {{ test.title }}
              </span>
              <span
                v-if="
                  evaluator_view &&
                  selected_user != null &&
                  evaluateeTestScore(test.id) != null
                "
              >
                <span
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Puntaje asignado'
                  "
                  >( {{ evaluateeTestScore(test.id) | Round }}</span
                >
                /
                {{ test.max_score }} )
              </span>
            </div>
          </template>
          <template
            v-if="
              openTabsIndex.includes(index) ||
              evaluator_view ||
              (!evaluator_view && !evaluatee_view)
            "
          >
            <!-- Botón Formatos de Salida -->
            <div class="text-left ml-2">
              <!-- Boton de diferentes Formatos -->
              <b-dropdown
                v-if="
                  evaluation &&
                  (evaluation.type == 1 ||
                    evaluation.type == 2 ||
                    evaluation.type == 3) &&
                  !allows_crud &&
                  fetch_completed &&
                  !evaluatee_view &&
                  !evaluator_view &&
                  user &&
                  !user.groups.includes(7)
                "
                split
                class="mt-4 mr-2 noprint"
                variant="primary"
                size="sm"
                @click="formatWrittenTest"
              >
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <b-icon icon="printer" class="mr-1"></b-icon>
                    <span>Formato de salida</span>
                  </div>
                </template>

                <b-dropdown-form style="width: 410px">
                  <b-form-checkbox
                    class="mt-1"
                    v-model="checked_rubric_partial"
                    name="check-score"
                    switch
                  >
                    Ocultar
                    <b>
                      {{
                        $getVisibleNames("evaluations2.rubric", false, "Pauta")
                      }}
                    </b>
                    al Imprimir
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="mt-1"
                    v-model="checked_score"
                    name="check-score"
                    switch
                  >
                    Ocultar <b>Puntaje</b> de la Pregunta al Imprimir
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="mt-1"
                    v-model="checked_weightning"
                    name="check-weightning"
                    switch
                  >
                    Ocultar <b>Ponderación</b> de la Pregunta al Imprimir
                  </b-form-checkbox>
                </b-dropdown-form>
              </b-dropdown>
              <!-- Boton de Claves -->
              <b-button
                v-if="
                  evaluation &&
                  (evaluation.type == 1 ||
                    evaluation.type == 2 ||
                    evaluation.type == 3) &&
                  showCorrectAnswer(test.evaluation_situation) &&
                  !allows_crud &&
                  fetch_completed &&
                  !evaluatee_view &&
                  !evaluator_view &&
                  !preview_evaluation &&
                  user &&
                  !user.groups.includes(7)
                "
                split
                class="mt-4 mr-2 noprint"
                variant="primary"
                size="sm"
                @click="formatCorrectAnswerPartial"
              >
                <div class="d-flex" style="align-items: center">
                  <b-icon icon="printer" class="mr-1"></b-icon>
                  <span
                    >Formato Claves y
                    {{
                      $getVisibleNames("evaluations2.rubric", false, "Pauta")
                    }}
                  </span>
                </div>
              </b-button>

              <!-- Boton Formato Examen Transversal - Información -->
              <b-button
                v-if="
                  evaluation &&
                  evaluation.type == 4 &&
                  !allows_crud &&
                  fetch_completed &&
                  !evaluatee_view &&
                  !evaluator_view &&
                  user &&
                  !user.groups.includes(7)
                "
                split
                class="mt-4 mr-2 noprint"
                variant="primary"
                size="sm"
                @click="formatTransversalExamInformation"
              >
                <div class="d-flex" style="align-items: center">
                  <b-icon icon="printer" class="mr-1"></b-icon>
                  <span>Formato de Información </span>
                </div>
              </b-button>

              <!-- Formato de salida con evaluaciones (preguntas)-->
              <b-dropdown
                v-if="
                  evaluation &&
                  evaluation.type == 4 &&
                  !allows_crud &&
                  fetch_completed &&
                  !evaluatee_view &&
                  !evaluator_view &&
                  user &&
                  !user.groups.includes(7)
                "
                split
                class="mt-4 mr-2 noprint"
                variant="primary"
                size="sm"
                @click="formatTransversalExam"
              >
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <b-icon icon="printer" class="mr-1"></b-icon>
                    <span>Formato de salida</span>
                  </div>
                </template>

                <b-dropdown-form style="width: 410px">
                  <b-form-checkbox
                    class="mt-1"
                    v-model="checked_transversal_specification_table"
                    name="check-score"
                    switch
                  >
                    Ocultar
                    <b> Tabla de Especificaciones </b>
                    al Imprimir
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="mt-1"
                    v-model="checked_rubric_transversal"
                    name="check-score"
                    switch
                  >
                    Ocultar
                    <b>
                      {{
                        $getVisibleNames("evaluations2.rubric", false, "Pauta")
                      }}
                    </b>
                    al Imprimir
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="mt-1"
                    v-model="checked_score"
                    name="check-score"
                    switch
                  >
                    Ocultar <b>Puntaje</b> de la Pregunta al Imprimir
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="mt-1"
                    v-model="checked_weightning"
                    name="check-weightning"
                    switch
                  >
                    Ocultar <b>Ponderación</b> de la Pregunta al Imprimir
                  </b-form-checkbox>
                </b-dropdown-form>
              </b-dropdown>

              <!-- Boton Formato Examen Transversal - Claves -->

              <b-button
                v-if="
                  evaluation &&
                  evaluation.type == 4 &&
                  showCorrectAnswer(test.evaluation_situation) &&
                  !allows_crud &&
                  fetch_completed &&
                  !evaluatee_view &&
                  !evaluator_view &&
                  !preview_evaluation &&
                  user &&
                  !user.groups.includes(7)
                "
                split
                class="mt-4 mr-2 noprint"
                variant="primary"
                size="sm"
                @click="formatTransversalCorrectAnswer"
              >
                <div class="d-flex" style="align-items: center">
                  <b-icon icon="printer" class="mr-1"></b-icon>
                  <span>Claves de Respuesta </span>
                </div>
              </b-button>
            </div>
            <ContainerInstrument
              class="pl-1 pr-1"
              v-if="
                !evaluator_view ||
                (evaluator_view &&
                  (evaluator_check_section ||
                    evaluator_check_evaluation ||
                    evaluator_check_tests))
              "
              :container_instrument_id="'upper_container_instrument'"
              :key="key_evaluation_criteria"
              :test="test"
              :key_evaluation_criteria="key_evaluation_criteria"
              :allows_crud="allows_crud"
              :evaluation_id="evaluation_id"
              :evaluatee_view="evaluatee_view"
              :evaluator_view="evaluator_view"
              :isPrintingRubrica="isPrintingRubrica"
              :isformatPartialTest="isformatPartialTest"
              :isformatTransversalInformation="isformatTransversalInformation"
              :isformatTransversalExam="isformatTransversalExam"
              :isPrintingInstrumento="isPrintingInstrumento"
              :preview_evaluation="preview_evaluation"
              :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
              :user_id="selected_user"
              :evaluation_situations="evaluation_situations"
              :matter_evaluation="matter_evaluation"
              :checked_rubric_partial="checked_rubric_partial"
              :checked_rubric_transversal="checked_rubric_transversal"
              :checked_transversal_specification_table="
                checked_transversal_specification_table
              "
              :checked_score="checked_score"
              :checked_weightning="checked_weightning"
              :isformatCorrectAnswerPartial="isformatCorrectAnswerPartial"
              :isformatCorrectAnswerTransversal="
                isformatCorrectAnswerTransversal
              "
              @change_preview_evaluation="previewEvaluation"
              @print_instrument="printInstrument"
              @print_rubric="printRubric"
              @previous_tabs="() => tabIndex--"
              @next_tabs="() => tabIndex++"
              @is_fetch_completed="isFetchCompleted"
            ></ContainerInstrument>
          </template>
        </b-tab>
        <!-- Botón Agregar nuevo Instrumento -->
        <template
          v-if="
            allows_crud &&
            evaluation &&
            !preview_evaluation &&
            !evaluation.test_creation_is_locked
          "
          #tabs-start
        >
          <div class="container-add-instrument">
            <b-button
              variant="primary"
              size="sm"
              @click="$bvModal.show(`modal-created-instrument`)"
              class="link-span add-new-instrument ml-0"
            >
              + Agregar
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
            </b-button>
          </div>
        </template>

        <!-- Renderizado si no hay tabs -->
        <template #empty>
          <div class="text-center text-muted">
            No hay ningún instrumento creado.<br />
            Para crear un nuevo instrumento seleccione el botón "Agregar
            {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}".
          </div>
        </template>
      </b-tabs>
    </b-card>
    <div
      v-if="!evaluator_view && !evaluatee_view"
      :class="{ 'd-none': !isfullImpressionEvaluation }"
    >
      <div v-for="(test, index) in testFilter" :key="test.id">
        <div :class="{ 'page-break': index != 0 }">
          <h3 class="mt-4">
            {{ test.title }}
          </h3>
          <ContainerInstrument
            v-if="
              !evaluator_view ||
              (evaluator_view &&
                (evaluator_check_section ||
                  evaluator_check_evaluation ||
                  evaluator_check_tests))
            "
            :container_instrument_id="'down_container_instrument'"
            :key="key_evaluation_criteria"
            :test="test"
            :key_evaluation_criteria="key_evaluation_criteria"
            :allows_crud="allows_crud"
            :evaluation_id="evaluation_id"
            :evaluatee_view="evaluatee_view"
            :evaluator_view="evaluator_view"
            :isPrintingRubrica="isPrintingRubrica"
            :isformatPartialTest="isformatPartialTest"
            :isformatTransversalInformation="isformatTransversalInformation"
            :isformatTransversalExam="isformatTransversalExam"
            :isPrintingInstrumento="isPrintingInstrumento"
            :preview_evaluation="preview_evaluation"
            :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
            :user_id="selected_user"
            :requests_within_the_component="false"
            :evaluation_situations="evaluation_situations"
            :matter_evaluation="matter_evaluation"
            :checked_rubric_partial="checked_rubric_partial"
            :checked_rubric_transversal="checked_rubric_transversal"
            :checked_transversal_specification_table="
              checked_transversal_specification_table
            "
            :checked_score="checked_score"
            :checked_weightning="checked_weightning"
            :isformatCorrectAnswerPartial="isformatCorrectAnswerPartial"
            :isformatCorrectAnswerTransversal="isformatCorrectAnswerTransversal"
            @change_preview_evaluation="previewEvaluation"
            @print_instrument="printInstrument"
            @print_rubric="printRubric"
            @previous_tabs="() => tabIndex--"
            @next_tabs="() => tabIndex++"
          ></ContainerInstrument>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      :id="`modal-created-instrument`"
      :title="`Crear ${$getVisibleNames(
        'evaluations2.test',
        false,
        'Instrumento'
      )}`"
      @hide="confirmClose"
      hide-footer
      size="xl"
    >
      <NewInstrumentForm
        :evaluation_id="evaluation_id"
        :show_step="
          evaluation &&
          (evaluation.matter_evaluation != null ||
            evaluation.egress_profile_matter_evaluation != null)
        "
        :Section="section"
        @change_evaluation_form="() => (change_evaluation_form = true)"
        @closeModal="createdInstrument"
      ></NewInstrumentForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewEvaluation",
  components: {
    HeaderEvaluation: () => import("./HeaderEvaluation"),
    HeaderOutputFormat: () =>
      import("@/components/new-evaluations/Evaluation/HeaderOutputFormat"),
    ContainerInstrument: () => import("./ContainerInstrument"),
    ViewEvaluationGrade: () => import("./ViewEvaluationGrade"),
    NewInstrumentForm: () => import("./NewInstrumentForm"),
    SelectedEvaluatee: () => import("./Evaluators/SelectedEvaluatee"),
    NewEvaluationForm: () =>
      import(
        "@/components/new-evaluations/ManageEvaluations/NewEvaluationForm"
      ),
  },
  props: {
    evaluation_id: {
      type: Number,
      required: true,
    },
    instrument_id: {
      type: Number,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
    },
  },
  data() {
    return {
      sections: [],
      matters: [],
      egress_profile_matters: [],
      tabIndex: -1,
      openTabsIndex: [],
      evaluation_criteria_micro_ids: [],
      matter_evaluation: null,
      key_evaluation_criteria: 0,
      preview_evaluation:
        this.evaluatee_view || this.evaluator_view ? true : false,
      isfullImpressionEvaluation: false,
      isPrintingRubrica: false,
      isformatPartialTest: false,
      isformatTransversalInformation: false,
      isformatTransversalExam: false,
      isPrintingInstrumento: false,
      selected_user: null,
      // view_output_format: null,
      evaluator_check_section: true,
      evaluator_check_evaluation: true,
      evaluator_check_tests: true,
      evaluation_situations: [],
      fetch_completed: false,
      prevent_close_modal: false,
      change_evaluation_form: false,
      checked_rubric_partial: false,
      checked_rubric_transversal: false,
      checked_transversal_specification_table: false,
      isformatCorrectAnswerPartial: false,
      isformatCorrectAnswerTransversal: false,
      checked_score: false,
      checked_weightning: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      sections_store: names.SECTIONS,
      evaluations: names.NEW_EVALUATIONS,
      Tests: names.NEW_TESTS,
      evaluatee_evaluations: names.EVALUATEE_EVALUATIONS,
      evaluatee_tests: names.EVALUATEE_TESTS,
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    filterEvaluateeTests() {
      if (this.selected_user == null) return [];
      else {
        const test_ids = this.Tests.filter(
          (x) => x.evaluation == this.evaluation_id
        ).map((x) => x.id);
        return this.evaluatee_tests.filter(
          (x) => test_ids.includes(x.test) && x.evaluatee == this.selected_user
        );
      }
    },
    isFinishTestEvaluation() {
      if (!this.evaluatee_view) return false;
      else {
        const test_ids = this.Tests.filter(
          (x) =>
            x.evaluation == this.evaluation_id &&
            x.evaluatees.includes(this.user.id) &&
            x.weighing > 0
        ).map((x) => x.id);
        const evaluatee_test = this.evaluatee_tests.filter(
          (x) =>
            x.evaluatee == this.user.id &&
            test_ids.includes(x.test) &&
            x.is_published == true
        );
        if (
          evaluatee_test.length == test_ids.length &&
          evaluatee_test.length != 0 &&
          test_ids.length != 0
        )
          return true;
        else return false;
      }
    },
    existEvaluateeEvaluation() {
      if (
        this.evaluatee_evaluations.filter(
          (x) => x.evaluation == this.evaluation_id
        ).length > 0
      )
        return true;
      else return false;
    },
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections_store.find(
          (x) => x.id == this.evaluation.scope_id
        );
      else return null;
    },
    testFilter() {
      if (this.evaluator_view && this.selected_user != null) {
        return this.Tests.filter(
          (x) =>
            x.evaluation == this.evaluation_id &&
            x.evaluatees.includes(this.selected_user)
        );
      }
      if (this.evaluator_view && this.selected_user == null) {
        return this.Tests.filter((x) => x.evaluation == this.evaluation_id);
      } else if (this.evaluatee_view) {
        return this.Tests.filter(
          (x) =>
            x.evaluation == this.evaluation_id &&
            x.evaluatees.includes(this.user.id) &&
            x.start_time != null &&
            x.end_time != null
        );
      }
      return this.Tests.filter((x) => x.evaluation == this.evaluation_id);
    },
  },
  methods: {
    showCorrectAnswer(evaluation_situation) {
      return this.evaluation_situations.some(
        (x) =>
          x.id == evaluation_situation &&
          x.internal_use_id == "duoc_prueba_seleccion_multiple_test"
      );
    },
    confirmClose(bvModalEvt) {
      if (
        bvModalEvt.trigger === "headerclose" ||
        bvModalEvt.trigger === "event"
      ) {
        this.change_evaluation_form = false;
        return;
      }
      if (!this.prevent_close_modal && this.change_evaluation_form) {
        bvModalEvt.preventDefault();
        this.$bvModal
          .msgBoxConfirm(
            "¿Estás seguro de que quieres cerrar? Los datos no guardados se perderán.",
            {
              title: "Confirmación Necesaria",
              size: "md",
              buttonSize: "md",
              okVariant: "warning",
              okTitle: "Sí, cerrar",
              cancelTitle: "Cancelar",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true,
              headerClass: "text-black",
              header: {
                title: "Confirmación Necesaria",
                class: "text-center",
              },
            }
          )
          .then((value) => {
            if (value) {
              this.prevent_close_modal = true;
              this.$bvModal.hide(bvModalEvt.componentId);
              this.$nextTick(() => {
                this.change_evaluation_form = false;
                this.prevent_close_modal = false;
              });
            }
          });
      }
    },
    evaluateeTestScore(test_id) {
      if (this.evaluator_view && this.selected_user != null) {
        let evaluatee_test = this.filterEvaluateeTests.find(
          (x) => x.test == test_id && x.evaluatee == this.selected_user
        );
        if (evaluatee_test)
          return evaluatee_test.final_score != null
            ? evaluatee_test.final_score
            : evaluatee_test.manual_score != null
            ? evaluatee_test.manual_score
            : evaluatee_test.automated_score != null
            ? evaluatee_test.automated_score
            : 0;
        else return null;
      } else return null;
    },
    slotEvaluateeSelected(evaluatee_id) {
      this.selected_user = evaluatee_id;
      this.key_evaluation_criteria++;
    },
    fetchMatterEvaluation(evaluation) {
      if (evaluation && evaluation.matter_evaluation)
        this.$store
          .dispatch(names.FETCH_MATTER_EVALUATION, evaluation.matter_evaluation)
          .then((response) => {
            this.matter_evaluation = response;
            this.fetchStudyUnitAndEvaluationCriteria(response.matter);
            this.evaluation_criteria_micro_ids =
              response.evaluation_criterias_micro;
            this.key_evaluation_criteria++;
          });
      if (evaluation && evaluation.egress_profile_matter_evaluation) {
        this.$restful
          .Get(
            `/teaching/egress-profile-matter-evaluation/${evaluation.egress_profile_matter_evaluation}/`
          )
          .then((response) => {
            this.matter_evaluation = response;
            this.$store
              .dispatch(
                names.FETCH_EGRESS_PROFILE_MATTER,
                response.egress_profile_matter
              )
              .then((egr_matter) => {
                this.fetchStudyUnitAndEvaluationCriteria(egr_matter.matter);
                this.evaluation_criteria_micro_ids =
                  response.evaluation_criterias_micro;
                this.key_evaluation_criteria++;
              });
          });
      }
    },
    fetchStudyUnitAndEvaluationCriteria(matter_id) {
      if (
        this.institution &&
        (this.institution.internal_use_id == "ciisa_uss" ||
          this.institution.show_competence_unit)
      )
        this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
          matter__id: matter_id,
        });
      this.$store.dispatch(names.FETCH_STUDY_UNITS, matter_id);
      this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
        matter_id: matter_id,
      });
    },
    changeStateEvaluation() {
      const payload = {
        new_evaluation_id: this.evaluation.id,
        item: {
          is_locked: !this.evaluation.is_locked,
        },
      };
      this.$store.dispatch(names.PATCH_NEW_EVALUATION, payload);
    },
    printRubric() {
      this.isPrintingRubrica = true;
      this.$nextTick(() => {
        window.print();
        this.isPrintingRubrica = false;
      });
    },
    formatWrittenTest() {
      this.isformatPartialTest = true;
      this.$nextTick(() => {
        window.print();
        this.isformatPartialTest = false;
      });
    },
    formatCorrectAnswerPartial() {
      this.isformatCorrectAnswerPartial = true;
      this.$nextTick(() => {
        window.print();
        this.isformatCorrectAnswerPartial = false;
      });
    },
    formatTransversalCorrectAnswer() {
      this.isformatCorrectAnswerTransversal = true;
      this.$nextTick(() => {
        window.print();
        this.isformatCorrectAnswerTransversal = false;
      });
    },
    formatTransversalExamInformation() {
      this.isformatTransversalInformation = true;
      this.isfullImpressionEvaluation = true;
      this.$nextTick(() => {
        window.print();
        this.isformatTransversalInformation = false;
        this.isfullImpressionEvaluation = false;
      });
    },
    formatTransversalExam() {
      this.isformatTransversalExam = true;
      this.$nextTick(() => {
        window.print();
        this.isformatTransversalExam = false;
      });
    },
    fullImpressionEvaluation() {
      this.isfullImpressionEvaluation = true;
      this.$nextTick(() => {
        window.print();
        this.isfullImpressionEvaluation = false;
      });
    },
    // viewOutputFormat(view_value = null) {
    //   this.view_output_format = view_value;
    //   this.$nextTick(() => {
    //     window.print();
    //     this.view_output_format = null;
    //   });
    // },
    printInstrument() {
      this.isPrintingInstrumento = true;
      this.$nextTick(() => {
        window.print();
        this.isPrintingInstrumento = false;
      });
    },
    previewEvaluation() {
      this.preview_evaluation = !this.preview_evaluation;
    },
    isFetchCompleted() {
      this.fetch_completed = true;
    },
    fetchTrimmedSecMatEPM() {
      this.$restful
        .Get(`/teaching/get_trimmed_sections_matters_and_epms/`)
        .then((response) => {
          this.sections = response.sections;
          this.matters = response.matters;
          this.egress_profile_matters = response.egress_profile_matters;
        });
    },
    pushDashboard() {
      this.$swal
        .fire({
          backdrop: `rgba(0,0,0,0.99)`,
          title: "Acceso Denegado",
          text: "No tienes permiso para realizar esta acción",
          type: "warning",
          showConfirmButton: false,
          timer: 5000, // Tiempo del temporizador en milisegundos (5000 ms = 5 segundos)
          timerProgressBar: true,
          willClose: () => {
            this.$router.push({ name: "DashboardView" });
          },
        })
        .then(() => {
          this.$router.push({ name: "DashboardView" });
        });
    },
    fetchEvaluationSituations() {
      this.$restful.Get(`/teaching/evaluation-situation/`).then((response) => {
        this.evaluation_situations = response;
      });
    },
    createdInstrument(instrument_id) {
      this.$bvModal.hide(`modal-created-instrument`);
      if (instrument_id != null)
        this.$store.dispatch(names.FETCH_NEW_TEST_EVALUATION_CRITERIA_MICROS, {
          test_id: instrument_id,
        });
    },
  },
  mounted() {},
  watch: {
    tabIndex() {
      if (!this.openTabsIndex.includes(this.tabIndex))
        this.openTabsIndex.push(this.tabIndex);
    },
    evaluator_check_section(value) {
      if (
        !value &&
        !this.evaluator_check_evaluation &&
        !this.evaluator_check_tests &&
        this.evaluator_view
      )
        this.pushDashboard();
    },
    evaluator_check_evaluation(value) {
      if (
        !value &&
        !this.evaluator_check_section &&
        !this.evaluator_check_tests &&
        this.evaluator_view
      )
        this.pushDashboard();
    },
    evaluator_check_tests(value) {
      if (
        !value &&
        !this.evaluator_check_section &&
        !this.evaluator_check_evaluation &&
        this.evaluator_view
      )
        this.pushDashboard();
    },
  },
  created() {
    this.fetchEvaluationSituations();
    this.$store.dispatch(names.FETCH_MULTIPLE_CHOICE_QUESTION_SUB_TYPES);
    this.$store
      .dispatch(names.FETCH_NEW_EVALUATION, this.evaluation_id)
      .then((response) => {
        if (
          this.evaluator_view &&
          this.user &&
          !response.evaluators.includes(this.user.id) &&
          !this.user.is_superuser &&
          !this.user.groups.includes(1)
        )
          this.evaluator_check_evaluation = false;
        if (response && response.scope == 1 && response.scope_id != null) {
          this.$store
            .dispatch(names.FETCH_SECTION, response.scope_id)
            .then((section) => {
              if (
                this.evaluator_view &&
                this.user &&
                !section.professors.includes(this.user.id) &&
                !this.user.is_superuser &&
                !this.user.groups.includes(1)
              )
                this.evaluator_check_section = false;
            });
          // Se buscan todas las secciones del profesor para poder aplicar el funcionamiento del
          // replicateTests para replicar multiples instrumentos en varias evaluaciones de distinta sección
          // pero del mismo parent_evaluation.
          if (!this.evaluator_view && !this.evaluatee_view && this.user)
            this.$store.dispatch(names.FETCH_SECTIONS, {
              professor_id: this.user.id,
            });
        }
        if (response.parent_evaluation != null) {
          this.$store
            .dispatch(names.FETCH_NEW_EVALUATION, response.parent_evaluation)
            .then((evaluation_parent) => {
              this.fetchMatterEvaluation(evaluation_parent);
            });
        } else this.fetchMatterEvaluation(response);
        if ([1, 2, 3].includes(response.scope)) this.fetchTrimmedSecMatEPM();
        else if (response.scope == 4)
          this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
        else if (response.scope == 5) this.$store.dispatch(names.FETCH_CAREERS);
        else if (response.scope == 6)
          this.$store.dispatch(names.FETCH_FACULTIES);
        else if (response.scope == 7)
          this.$store.dispatch(names.FETCH_CAMPUSES);
      });
    this.$store
      .dispatch(names.FETCH_NEW_TESTS, {
        evaluation_id: this.evaluation_id,
      })
      .then((response) => {
        const tests_ids = response.map((x) => x.id);
        const evaluatee_ids = [
          ...new Set(response.map((x) => x.evaluatees).flat()),
        ];
        this.$store.dispatch(names.FETCH_ALT_USERS, {
          user_ids: evaluatee_ids,
        });
        this.$store.dispatch(names.FETCH_NEW_RUBRIC_INSTRUMENTS, {
          tests_ids: tests_ids,
        });
        if (
          (!this.evaluatee_view && !this.evaluator_view) ||
          (!this.evaluatee_view && this.evaluator_view)
        ) {
          this.$store.dispatch(names.FETCH_EVALUATEE_TESTS, {
            evaluatees_ids: evaluatee_ids,
            tests_ids: tests_ids,
          });
        }
        this.$store.dispatch(names.FETCH_NEW_TEST_EVALUATION_CRITERIA_MICROS, {
          tests_ids: tests_ids,
        });
        this.$store.dispatch(names.FETCH_NEW_TEST_GROUPS, {
          tests_ids: tests_ids,
        });
        if (
          this.evaluator_view &&
          this.user &&
          response.filter((x) => x.evaluators.includes(this.user.id)).length ==
            0 &&
          !this.user.is_superuser &&
          !this.user.groups.includes(1)
        )
          this.evaluator_check_tests = false;
        if (this.instrument_id) {
          const index = response.findIndex((x) => x.id == this.instrument_id);
          if (index != -1) this.tabIndex = index;
        } else if (response && response.length > 0) this.tabIndex = 0;
      });
    if (!this.evaluatee_view && !this.evaluator_view) {
      this.$store.dispatch(names.FETCH_EVALUATEE_EVALUATIONS, {
        evaluation_id: this.evaluation_id,
      });
    }
  },
};
</script>

<style scoped>
.spinner-tab {
  margin-bottom: 2px;
}
.container-add-instrument {
  display: flex;
  align-items: center;
}
.add-new-instrument {
  max-height: 31px;
}
.lock-btn {
  border: 1px;
  height: 27px;
  width: 27px;
}
.container-btn-print {
  display: flex;
  justify-content: right;
}
.btn-preview-evaluation,
.btn-preview-evaluation-no-edit {
  display: flex;
  justify-content: right !important;
}
.badge-profile {
  background: white;
  color: #007bff;
  border: 1px solid #c2c2c2;
}
.title-presentation {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: black;
  margin-top: 5px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}
.container-buttons-evaluation {
  display: flex;
  justify-content: space-between;
  margin-top: 1.2em;
  margin-bottom: 0.5em;
}
.btn-is-locked-activate {
  display: flex;
  justify-content: right;
}
.container-buttons-instrument {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.lock-instrument-right {
  display: flex;
  justify-content: right !important;
}
.container-instrument {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card {
  border-radius: 0 !important;
}
.tabs-instrument {
  transition: all 0.3s ease;
  border: none;
}
.card-body {
  padding: 5px;
}
.link-span {
  cursor: pointer;
  color: #007bff;
}
.link-span:hover {
  background: #007bff;
  color: white;
}
.b-dropdown-form {
  padding: 0;
}
.page-break {
  page-break-before: always;
  page-break-after: auto;
  page-break-inside: avoid;
}
@media print {
  .container-instrument {
    border: none;
  }
  .print-hide {
    display: none !important;
  }
  .print-show {
    display: block !important;
  }
  .container-buttons-evaluation {
    margin-top: 0;
  }
  /* Oculta los tabs al momento de imprimir */
  .tabs >>> ul {
    display: none !important;
  }
}
@media (max-width: 750px) {
  .title-presentation {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
  }
}
</style>
